<template>
    <div class="e-commerce">
        <h4 class="pt-3">E-commerce</h4>
        <hr>
        <div class="row">
            <div class="col-12">
                <itc-card v-if="data" color="#343a40" class="custom-shadow h-100">
                    <div slot="header" class="text-white text-center"><h3>Estado general</h3></div>
                    <div slot="body">
                        <div class="row">
                            <div class="col-6">
                                <itc-switch title="Estado de la pagina" :disabled="false" :data="data.serverData.value.isOn" @change-value="changeServerData($event, 'isOn')"></itc-switch>
                                <hr>
                                <itc-switch title="Mostrar precios" :disabled="false" :data="data.serverData.value.showPrices" @change-value="changeServerData($event, 'showPrices')"></itc-switch>
                            </div>
                            <div class="col-6">
                                <itc-switch title="Ventas online" :disabled="true" :data="false" @change-value="changeServerData($event, 'isOn')"></itc-switch>
                                <hr>
                                <itc-switch title="Facturación electrónica" :disabled="true" :data="false" @change-value="changeServerData($event, 'showPrices')"></itc-switch>
                            </div>
                        </div>
                    </div>
                </itc-card>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-12">
                <itc-card v-if="data" color="#343a40" class="custom-shadow">
                    <div slot="header" class="text-white text-center"><h3>Carrusel</h3></div>
                    <div slot="body">
                        <input 
                            @change="onFileChange"
                            style="display:none"
                            ref="carouselFile"
                            type="file" class="form-control-file">
                        <div class="row">
                            <div class="col-6 text-center">
                                <h5>Carrusel grande</h5><small class="text-muted">1920 x 515</small>
                                <hr>
                                <div 
                                    v-for="(path, i) in 3" :key="i" 
                                    class="div custom-shadow text-center mt-3">
                                    <img 
                                        @click.prevent="onCarouselClick('lg', i)"
                                        :src="`${publicPath}${data.carouselPaths[i]}`"
                                        class="w-100 custom-shadow  set-pointer">
                                    <h6 class="text-center text-white p-1" style="background-color:#343a40;font-weight:bold">Imagen: {{ i + 1 }}</h6>
                                </div>
                            </div>
                            <div class="col-6 text-center">
                                <h5>Carrusel celular</h5><small class="text-muted">1024 x 576</small>
                                <hr>
                                <div
                                    v-for="(path, i) in 3" :key="i" 
                                    class="custom-shadow w-50 mt-3"
                                    style="margin:auto">
                                    <img 
                                        @click.prevent="onCarouselClick('sm', i)"
                                        :src="`${publicPath}${data.carouselPaths[i + 3]}`"
                                        class="custom-shadow set-pointer w-100">
                                    <h6 class="text-center text-white p-1" style="background-color:#343a40;font-weight:bold">Imagen: {{ i + 1 }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </itc-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

import Card from '@/components/mix/Card'
import Switch from '@/components/mix/Switch'

export default {
    data(){
        return {
            data: null,
            clickedImage: {
                carousel: '',
                index: ''
            },
            publicPath: process.env.VUE_APP_PUBLIC_PATH
        }
    },
    methods: {
        onCarouselClick(carousel, index){
            this.clickedImage = {
                carousel,
                index: index + 1
            } 
            this.$refs.carouselFile.click()
        },
        changeFileName(){
            return (`carousel_${this.clickedImage.carousel}_${this.clickedImage.index}.jpg`)
        },
        async onFileChange(event){
            const fd = new FormData()
            const file = event.target.files[0]
            fd.append('carousel-img', file, this.changeFileName())
            const resp = await axios.post('/carousel-image', fd)
            const index = this.clickedImage.carousel == 'lg' ? this.clickedImage.index - 1 : this.clickedImage.index + 3 - 1
            this.data.carouselPaths.splice(index, 1, resp.data)
        },
        async changeServerData(event, type){
            if(type == 'isOn')
                this.data.serverData.value.isOn = event;
            else
                this.data.serverData.value.showPrices = event;
            await axios.post(`/server-info`, {
                value: this.data.serverData.value
            })
        },
    },
    components: {
        'itc-card': Card,
        'itc-switch': Switch
    },
    async created(){
        const resp = await axios.get('/ecommerce-info');
        this.data = resp.data
    }
}
</script>

<style lang="scss" scoped>
.mobile-carousel{
    width: 50% !important;
}
.fp-item:hover{
    background-color: transparentize($color: #D3D3D3, $amount: 0.7);
}

th {
  text-align: left;
}

th, td {
    border-bottom: 1px solid transparentize($color: #D3D3D3, $amount: 0.2);
    padding: 5px;
}
</style>