<template>
  <div class="switch-checkbox px-4">
    <div class="row">
      <div class="col-9">
        <h6>{{title}}</h6>
      </div>
      <div class="col-3">
        <label class="switch">
          <input
            :disabled="disabled"
            v-model="value"
            @change="$emit('change-value', value)"
            type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      value: '',
    }
  },
  props: {
    data: Boolean,
    title: String,
    disabled: Boolean
  },
  created(){
    this.value = this.data
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #32CD32;
}

input:focus + .slider {
  box-shadow: 0 0 1px #32CD32;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>